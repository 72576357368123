<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List DPT</h3>
        </div>
        <div class="panel-body p-5">
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :search-options="{
              enabled: true,
              trigger: 'keyup',
              placeholder: 'Masukkan Nama',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-white mr-2"
                >
                  <i v-if="isLoading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
                <!-- <router-link to="/pasien/create" class="btn btn-primary mr-2">
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Data</span>
                </router-link> -->
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <router-link
                    :to="'/data-dpt/' + props.row.id_dpt"
                    class="btn btn-circle btn-icon btn-success m-r-5"
                  >
                    <i class="fa fa-search"></i>
                  </router-link>
                  <router-link
                    :to="'/data-dpt/' + props.row.id_dpt + '/update'"
                    class="btn btn-circle btn-icon btn-warning m-r-5"
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </router-link>
                  <button
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.id_dpt)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "nama",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "L/P",
          field: "j_kelamin",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Usia",
          field: "usia",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kecamatan",
          field: "kec",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Desa",
          field: "kel",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Dusun",
          field: "rw",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "RT",
          field: "rt",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "TPS",
          field: "tps",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      searchTerm: "",
      timeoutSearch: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        this.fetchData();
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },

    fetchData() {
      axios
        .get("/v1/dpt/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/dpt/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>